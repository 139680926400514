import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Background from "../images/Bg6.png"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../components/seo"

class ServicePageTemplate extends React.Component {
    render() {
        const servicepage = this.props.data.contentfulServicepage
        let title = servicepage.cityName + " SEO | Professional SEO Company | Digital Marketing Agency"
        let keywords = [servicepage.cityName + " SEO", servicepage.cityName + " Internet Marketing", servicepage.cityName + " Digital Marketing", servicepage.cityName + " Search Engine Optimization", servicepage.cityName + " website leads"]
        let location = "/" + servicepage.slug
        let description = "Pyxel Development offers professional " + servicepage.cityName + " SEO, " + servicepage.cityName + " Digital Marketing, and " + servicepage.cityName + " Internet Marketing services that are guaranteed to boost your business to the next level at an affordable rate."
        let isProduct = true
        let productName = servicepage.cityName + " SEO"
        let sku = "10000001"
        return (
            <Layout>
                <Seo title={title} keywords={keywords} location={location} description={description} isProduct={isProduct} productName={productName} sku={sku} />
                <div className="bg-no-repeat bg-cover bg-left"
                    style={{
                        backgroundImage: `url(${Background})`,
                        width: '100%',
                        height: '100%',
                        backgroundAttachment: 'absolute',
                    }}>
                    <div className="container grid gap-x-5 lg:grid-cols-2 text-white font-bold p-10 pt-36 mx-auto xl:py-40">
                        <div className="flex justify-center lg:justify-end">
                            <div>
                                <p>SERVICE</p>
                                <h1 className="text-5xl md:text-6xl xl:text-7xl font-pixeboy">
                                    {servicepage.title}
                                </h1>
                                <h2 className="text-md md:text-xl max-w-lg mt-7">
                                    GAIN NEW LEADS AND MAXIMIZE THE ONLINE PRESENCE OF YOUR BUSINESS WITH CUSTOM {servicepage.boldName} SEO
                                </h2>
                                <div className="max-w-2xl xl:max-w-xl font-normal text-xs md:text-base">
                                    <p className="mt-7 md:mt-12">
                                        Creating a successful website for your business requires a variety of different behind-the-scenes SEO techniques in order to maximize client leads, website traffic, and the online presence of your website. <br /><br />
                                        With our {servicepage.cityName} SEO service, we can improve the online ranking of your website by optimizing your SEO and getting your business on the front page of Google and other relevant search engines. Pyxel Development’s
                                        {servicepage.cityName} SEO services are guaranteed to help you reach your target audience and increase your return on investment!
                                    </p>
                                    <div className="hidden lg:block mt-10 md:mt-12 xl:mt-28">
                                        <Link to="/request-quote" title="Request a Free Quote" alt="Request a Free Quote">
                                            <button className="relative h-10 px-6 text-white transition-colors bg-transparent rounded-lg border border-white focus:shadow-outline hover:bg-white hover:text-gray-600">
                                                <span>Request Quote</span>
                                            </button>
                                        </Link>
                                        <div className="hover:text-gray-600 mt-4">
                                            <a className="font-normal mt-1 text-sm" href="tel:916-821-9044" title="Call Pyxel Development" alt="Call Pyxel Development">
                                                Call Us: (916) 821-9044
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center pt-10 lg:pt-0 mx-auto max-w-xs md:max-w-sm md:max-w-md">
                            <StaticImage src="../images/SEO-fundamentals.png" placeholder="none" title={servicepage.cityName + " SEO"} alt={servicepage.cityName + " SEO"} />
                        </div>
                        <div className="lg:hidden mx-auto mt-10 md:mt-12 xl:mt-28">
                            <Link to="/request-quote" title="Request a Free Quote" alt="Request a Free Quote">
                                <button className="relative h-10 px-6 text-white transition-colors bg-transparent rounded-lg border border-white focus:shadow-outline hover:bg-white hover:text-gray-600">
                                    <span>Request Quote</span>
                                </button>
                            </Link>
                            <div className="hover:text-gray-600 mt-4">
                                <a className="font-normal mt-1 text-sm" href="tel:916-821-9044" title="Call Pyxel Development" alt="Call Pyxel Development">
                                    Call Us: (916) 821-9044
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bg-banner">
                    <div className="py-10 md:py-24 md:px-5">
                        <div className="flex items-center xl:container mx-auto grid md:gap-x-10 lg:gap-x-14 text-center md:grid-cols-2">
                            <div className="px-10 md:p-0 text-white">
                                <div className="md:-mt-6">
                                    <h2 className="text-2xl font-bold md:text-2xl xl:text-4xl">
                                        DO I NEED SEO?
                                    </h2>
                                    <p className="text-xs lg:text-sm">
                                        <br />Short answer - <b>YES!</b> When potential clients search for your {servicepage.cityName} service on the internet, it is essential that your organization appears on the first page in order to bring you the most business.<br /><br />
                                        The first page of Internet search results capture over 90% of the in-coming traffic. What does this mean for your website? It means your website MUST appear on the first page in order to take advantage of the online
                                        traffic in {servicepage.cityName}. <br /><br />A combination of strategically placed content, keywords, and metadata is required to get the highest SEO score for your website. You can trust Pyxel Development’s {servicepage.cityName} SEO Service to help maximize
                                        the ranking of your website.
                                    </p>
                                </div>
                            </div>
                            <div className="max-w-xl mx-auto pt-10 md:pt-0 pl-14 md:pl-0 ">
                                <StaticImage src="../images/seo-magic-ranking.png" placeholder="none" title={servicepage.cityName + " SEO agency"} alt={servicepage.cityName + " SEO agency"} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bg-white py-10 md:py-24 mx-auto px-5">
                    <div className="container mx-auto">
                        <h2 className="text-center font-bold text-2xl md:text-4xl">OUR SEO SOLUTION</h2>
                        <div className="text-center container max-w-xs mx-auto py-5 mb-3 md:mt-10 md:grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 md:gap-10 md:max-w-full">
                            <div>
                                <div className="hidden md:flex">
                                    <StaticImage className="mb-2 w-24 lg:w-32 mx-auto" src="../images/data.png" placeholder="none" title={servicepage.cityName + " SEO analysis"} alt={servicepage.cityName + " SEO analysis"} />
                                </div>
                                <h3 className="font-bold text-lg py-3">Competitor Analysis</h3>
                                <p className="text-sm md:text-base">We perform in-depth research on who your competitors are and gain an understanding of their SEO strategies</p>
                                <div className="md:hidden">
                                    <StaticImage className="w-24 mt-6 mb-8 md:mb-2" src="../images/data.png" placeholder="none" title={servicepage.cityName + " SEO analysis"} alt={servicepage.cityName + " SEO analysis"} />
                                </div>
                            </div>
                            <div className="xl:mt-48">
                                <div className="hidden md:flex">
                                    <StaticImage className="mb-2 w-24 lg:w-32 mx-auto" src="../images/link-building.png" placeholder="none" title={servicepage.cityName + " SEO link building"} alt={servicepage.cityName + " SEO link building"} />
                                </div>
                                <h3 className="font-bold text-lg py-3">Link Building</h3>
                                <p className="text-sm md:text-base">We publish articles on your website and on websites with a high domain-authority to increase the amount of links pointing to your website</p>
                                <div className="md:hidden">
                                    <StaticImage className="w-24 mt-6 mb-8 md:mb-2" src="../images/link-building.png" placeholder="none" title={servicepage.cityName + " SEO link building"} alt={servicepage.cityName + " SEO link building"} />
                                </div>
                            </div>
                            <div>
                                <div className="hidden md:flex">
                                    <StaticImage className="mb-2 w-24 lg:w-32 mx-auto" src="../images/keyword.png" placeholder="none" title={servicepage.cityName + " SEO keyword optimization"} alt={servicepage.cityName + " SEO keyword optimization"} />
                                </div>
                                <h3 className="font-bold text-lg py-3">Keyword Optimization</h3>
                                <p className="text-sm md:text-base">We find the optimal keywords to ensure maximum growth and audience outreach.</p>
                                <div className="md:hidden">
                                    <StaticImage className="w-24 mt-6 mb-8 md:mb-2" src="../images/keyword.png" placeholder="none" title={servicepage.cityName + " SEO keyword optimization"} alt={servicepage.cityName + " SEO keyword optimization"} />
                                </div>
                            </div>
                            <div className="xl:mt-48">
                                <div className="hidden md:flex">
                                    <StaticImage className="mb-2 w-24 lg:w-32 mx-auto" src="../images/analytic.png" placeholder="none" title={servicepage.cityName + " SEO analytics"} alt={servicepage.cityName + " SEO analytics"} />
                                </div>
                                <h3 className="font-bold text-lg py-3">Analytics</h3>
                                <p className="text-sm md:text-base">We provide monthly evaluation of your websites performance and standings against your competition to ensure that the SEO strategy is experiencing peak performance. </p>
                                <div className="md:hidden">
                                    <StaticImage className="w-24 mt-6 mb-8 md:mb-2" src="../images/analytic.png" placeholder="none" title={servicepage.cityName + " SEO analytics"} alt={servicepage.cityName + " SEO analytics"} />
                                </div>
                            </div>
                            <div>
                                <div className="hidden md:flex">
                                    <StaticImage className="mb-2 w-24 lg:w-32 mx-auto" src="../images/ranking.png" placeholder="none" title={servicepage.cityName + " SEO on site optimization"} alt={servicepage.cityName + " SEO on site optimization"} />
                                </div>
                                <h3 className="font-bold text-lg py-3">On-Site SEO</h3>
                                <p className="text-sm md:text-base">We modify existing website code to include proper META tags, titles, alts, descriptions, and keywords. Mobile optimization and increasing page performance is also included. </p>
                                <div className="md:hidden">
                                    <StaticImage className="w-24 mt-6 mb-8 md:mb-2" src="../images/ranking.png" placeholder="none" title={servicepage.cityName + " SEO on site optimization"} alt={servicepage.cityName + " SEO on site optimization"} />
                                </div>
                            </div>
                            <div className="xl:hidden">
                                <div className="hidden md:block">
                                    <StaticImage className="mb-2 w-24 lg:w-32 mx-auto" src="../images/first-prize.png" placeholder="none" title={servicepage.cityName + " local SEO"} alt={servicepage.cityName + " local SEO"} />
                                </div>
                                <h3 className="font-bold text-lg py-3">Local SEO</h3>
                                <p className="text-sm md:text-base">We optimize your Google business page to ensure that your business is being recommended locally in your area.
                                    46% of all Google searches have "local" intent and most searches priotize the "Near Me" feature.</p>
                                <div className="md:hidden">
                                    <StaticImage className="w-24 mt-3" src="../images/first-prize.png" placeholder="none" title={servicepage.cityName + " local `SEO"} alt={servicepage.cityName + " local SEO"} />
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-center md:-mt-10">
                            <Link to="/request-quote" title="Request a Free Quote" alt="Request a Free Quote" >
                                <button className="m-5 relative h-10 px-6 text-xs text-black transition-colors bg-transparent rounded-lg border border-black focus:shadow-outline hover:bg-black hover:text-white md:mt-20">
                                    <span>Get Started <b>TODAY!</b></span>
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>

                <div className="bg-offblue">
                    <div className="py-10 md:py-24">
                        <div className="px-5 text-center">
                            <div className="mx-auto max-w-5xl">
                                <h2 className="mx-auto text-2xl font-bold md:text-4xl text-white">
                                    WHAT IS SEO?
                                </h2>
                                <p className="text-xs mx-auto lg:text-sm 2xl:text-base">
                                    <br />SEO stands for “Search Engine Optimization.” In this process, websites gain organic growth through internet search engines. Our {servicepage.cityName} SEO service attracts traffic directly to your website, bringing
                                    in clientele and increasing your profits. We focus on specific keywords for search engines to recommend your website when potential customers are searching for services or products that you may offer.<br /><br />
                                    Unlike advertisements that appear on the screens of random viewers, our {servicepage.cityName} SEO service is focused specifically on reaching your target audience without the inefficiency that traditional advertisements provide.
                                </p>
                            </div>
                        </div>
                        <div className="flex order-2 md:justify-end md:order-1 pt-7">
                            <StaticImage src="../images/seo-search-results.png" className="mx-auto w-40 md:w-56 lg:w-72" placeholder="none" title={servicepage.cityName + " SEO company"} alt={servicepage.cityName + " SEO company"} />
                        </div>
                    </div>
                </div>
            </Layout >
        )
    }
}
export default ServicePageTemplate
export const pageQuery = graphql`
query ContentfulServicepageBySlug($slug: String !) {
    site {
        siteMetadata {
            title
        }
    }
    contentfulServicepage( slug: {eq: $slug }) {
        title
        cityName
        boldName
        slug
    }
}
`